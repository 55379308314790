import React from "react";

const Ouraim = () => {
  return (
    <div className="bg-[#F5F5F5] pb-[80px]">
      <div className="container mx-auto pt-[30px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 mt-[40px] gap-[30px] px-3 ">
          <div
            className="border shadow-lg pt-[50px]  rounded-xl  bg-[#FFFFFF] "
            id="goal"
          >
            <div className="pb-7 px-16">
              <div className="flex">
                <img
                  className="h-[30px] w-[20px] pt-3 "
                  src="./assets/Polygon.png"
                  alt=""
                />
                <h2 className="text-[#1C1C1C] text-[26px] font-semibold">
                  Become a business client
                </h2>
              </div>

              <p className="text-[#1C1C1C]  pt-2 text-[18px]">
                For companies: we provide a top-up balance, legal documents and
                receipts. And for large volumes - a personal manager and lower
                rates
              </p>
              <h1 className=" flex gap-2 text-[#1C1C1C]  pt-2 text-[23px] px-4 font-semibold">
                Contact Us On Whatsapp
                <a href="https://wa.me/7827460758?text=I%20am%20interested%20in%20Three%20Parcel %20services.">
                  <img className="" src="./assets/whatsapp.png" alt="" />
                  <div className="absolute mt-[-32px] ml-2 h-[30px] w-[30px] rounded-full bg-[#09b6eb] animate-ping"></div>
                </a>
              </h1>
            </div>
          </div>
          <div className="border shadow-lg pt-[50px]  rounded-xl  bg-[#FFFFFF]">
            <div className="pb-10 px-16">
              <div className="flex">
                <img
                  className="h-[30px] w-[20px] pt-3"
                  src="./assets/Polygon.png"
                  alt=""
                />
                <h2 className="text-[#1C1C1C] text-[26px] font-semibold">
                  Try a Single Delivery Right Now
                </h2>
              </div>
              <p className="text-[#1C1C1C]  pt-2 text-[18px]">
                Make an order in 20 seconds to try our best options. Ideal for a
                one-time delivery: no need to register or sign a contract
              </p>
            </div>
          </div>
        </div>

        <div className="pt-[100px]">
          <div className="flex items-center justify-center mx-auto flex-col mt-[-90px]">
            <h1 className=" lg:text-[70px] font-bold text-[#EBEBEB]">
              Our Aim and Goals
            </h1>
            <h1 className=" text-[36px] font-bold text-[#0E1D34] pt-[-9]">
              Our Aim and Goals
            </h1>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3">
        <div className="pl-[20%] mt-[35%]">
          <img src="./assets/1.png" alt="" />
        </div>
        <div>
          <div className="flex gap-[15px]">
            <h1 className="text-[115px] font-extrabold">1</h1>
            <div>
              <p className=" text-[24px] pt-[20%] font-semibold leading-6 text-[#0E1D34]">
                We are looking <br /> for expansion of Service <br /> in all
                cities of India
              </p>
            </div>
          </div>
          <div className="flex gap-[15px] pl-[30%]">
            <h1 className="text-[115px] font-extrabold">2</h1>
            <div>
              <p className=" text-[24px] pt-[15%] font-semibold leading-6 text-[#0E1D34]">
                Increase the Number of
                <br />
                Couriers and Driver partners <br /> so that we can fulfill every
                <br /> single order
              </p>
            </div>
          </div>
          <div className="flex gap-[15px]">
            <h1 className="text-[115px] font-extrabold">3</h1>
            <div>
              <p className=" text-[24px] pt-[15%] font-semibold leading-6 text-[#0E1D34]">
                Increase the Number of
                <br />
                Customers in all city so that <br />
                we can achieve 1 Million
                <br /> orders in a month.
              </p>
            </div>
          </div>
          <div className="flex gap-[15px] pl-[30%]">
            <h1 className="text-[115px] font-extrabold">4</h1>
            <div>
              <p className=" text-[24px] pt-[20%] font-semibold leading-6 text-[#0E1D34]">
                Build our Strong Support <br /> System so that our <br />
                Operation runs hassle-free.
              </p>
            </div>
          </div>
        </div>
        <div>
          <img src="./assets/2.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Ouraim;
