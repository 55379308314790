import React from "react";

const Contact = () => {
  return (
    <div className="bg-[#F5F5F5] pb-[80px]">
      <div>
        <div
          className="flex items-center justify-center mx-auto flex-col"
          id="contact"
        >
          <h1 className=" text-[34px] font-semibold text-[#000000] mt-4 ">
            FIND US
          </h1>
          <h1 className=" text-[34px] font-bold text-[#000000] mt-4 ">
            Access us easily
          </h1>
        </div>
      </div>
      <div className=" container mx-auto pt-[30px]">
        <div className="grid grid-cols-1 sm:grid-cols-2 px-3">
          <div className="flex flex-col justify-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.393940354601!2d77.06831871455859!3d28.58795649284902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b3c4ac3971f%3A0x3d95cc4e520239ca!2sHarijan%20Basti!5e0!3m2!1sen!2sin!4v1680173945234!5m2!1sen!2sin"
              className="lg:h-[400px] lg:w-[765px]sm:w-auto"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="bg-[#FEFCFA] flex flex-col justify-center">
            <div className="flex flex-col lg:text-[30px] sm:text-[20px] text-[#464558] py-2 px-[25%]">
              <h1>Contact with us</h1>
            </div>
            <div className="max-w-[450px] w-full mx-auto  p-2 px-8 rounded-lg">
              <div className="flex  gap-3 text-[#5C5B6C] text-[16px] py-3">
                <img
                  className="h-[25px] w-[18px] mt-1"
                  src="./assets/location.png"
                  alt=""
                />
                <p>
                  Plot No.17 Kh. No-31/24,17 ,14 and 25 ,,2nd floor Sec-7
                  harijan basti Dwarka ,110045
                </p>
              </div>
              <div className="flex  gap-3 text-[#5C5B6C] text-[16px] py-2">
                <img
                  className="h-[21px] w-[21px] mt-3"
                  src="./assets/www.png"
                  alt=""
                />
                <p>
                  www.threeparcel.com <br />
                  help@threeparcel.com
                </p>
              </div>
              <div className="flex  gap-3 text-[#5C5B6C] text-[16px] py-2">
                <img
                  className="h-[17px] w-[22px] mt-1"
                  src="./assets/gmail.png"
                  alt=""
                />
                <p>sales.enquiry@threeparcel.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 className=" flex justify-center text-center mt-6 ">
        <button className=" flex gap-3 justify-center text-center text-[#fff] bg-[#FE6F23] w-auto  font-semibold py-4 px-8 border  rounded-[70px] ">
          <img className="h-[20px]" src="./assets/phone.png" alt="" />
          <div className="absolute mt-[-3px] ml-[-9%] z-[-20px]  h-[25px] w-[30px] rounded-full bg-[#09b6eb] animate-ping"></div>
          <a href="tel:7827460758">Call us at 7827460758</a>
        </button>
      </h1>
    </div>
  );
};

export default Contact;
