import React from "react";

const Mobile = () => {
  return (
    <div className="bg-[#FAFAFA] ">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-[60px]">
          <div>
            <img className="mt-[-50px]" src="./assets/mobile.png" alt="" />
          </div>
          <div className="mt-[70px] pl-[30px]">
            <h1 className="lg:text-[58px] sm:text-[10%] font-bold pl-[50px]">
              Three Parcel App
            </h1>
            <div className="lg:pl-[30%]">
              <button className=" text-[#FFFFFF] bg-[#FE6F23] h-auto   font-semibold py-2 px-4 border  rounded-[50px] mt-4  text-[26px]">
                Coming Soon
              </button>
            </div>

            <div className="flex gap-6 mt-9">
              <div>
                <img src="./assets/Google.png" alt="" />
              </div>
              <div>
                <img src="./assets/Appstore.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
