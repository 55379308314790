import React from "react";

const Header = () => {
  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-items-center mt-[40px] pb-[40px]">
        <div>
          <div className="container mx-auto">
            <div>
              <h1 className="lg:text-[40px] md:text-[35px] mt-[30%]">
                A trusted provider of
              </h1>
              <h1 className="lg:text-[40px] md:text-[35px] text-[#261134] font-bold">
                delivery services.
              </h1>
              <div className="text-[#4D4D4D] mt-4">
                We deliver your products safely to your home in a
              </div>
              <div className="text-[#4D4D4D] ">on time.</div>
              <a href="#contact">
                <button className=" text-[#FFFFFF] bg-[#FE6F23] h-[50px] w-[151px]  font-semibold py-2 px-4 border  rounded-[50px] mt-4 ">
                  Get started
                </button>
              </a>
              <div className="divide-y divide-dashed md:divide-solid bg-black mt-10 border-b-[2px] w-[100%]"></div>
            </div>
            <div className=" grid grid-cols-3 mt-[30px]  gap-6">
              <div className="space-y-[10px] flex flex-col items-start justify-start">
                <img className="" src="./assets/Delhi.png" alt="" />
                <h2 className="pl-3">Delhi</h2>
              </div>
              <div className="space-y-[10px] flex flex-col items-start justify-start  ">
                <img className="" src="./assets/Mumbai.png" alt="" />
                <h2 className="">Mumbai</h2>
              </div>
              <div className="space-y-[1px] flex flex-col items-start justify-start mt-[-13px]">
                <img className="" src="./assets/Pune.png" alt="" />
                <h2 className="pl-6 pt-[3px]">Pune</h2>
              </div>
            </div>
            <div className=" grid grid-cols-3 mt-[30px]  gap-6">
              <div className="space-y-[10px] flex flex-col items-start justify-start">
                <img className="" src="./assets/bangalore.png" alt="" />
                <h2 className="mt-[-1]">Bangalore</h2>
              </div>
              <div className="space-y-[10px] flex flex-col items-start justify-start ">
                <img className="" src="./assets/hyderabad.png" alt="" />
                <h2 className="pt-1 ml-[-7px]">Hyderabad</h2>
              </div>
              <div className="space-y-[10px] mt-1 flex flex-col items-start justify-start">
                <img
                  className="pl-[10px] h-[60px]"
                  src="./assets/Plus.png"
                  alt=""
                />
                <div className="ml-[-10px]">
                  <h2 className="pl-[-10px]"> launching soon in </h2>
                  <h2 className="pl-4"> more places </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="absolute top-0 left-0  right-0 z-[-10]"
          src="./assets/Vector3.png"
          alt=""
        />
        <div>
          <img src="./assets/Banner_img.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
